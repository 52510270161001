import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    accessToken: localStorage.getItem('accessToken'),
  }),
  actions: {
    persistToLocalStorage() {
      if (this.accessToken !== null) {
        localStorage.setItem('accessToken', this.accessToken)
      }
    },
    setAccessToken(accessToken: string | null) {
      this.accessToken = accessToken
      this.persistToLocalStorage()
    },
    cleanAuthStore() {
      localStorage.removeItem('accessToken')
    },
  },
})
