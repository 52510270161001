import { useUserStore } from '../stores/user-store'

interface GetProfileResponse {
  data: {
    email: string
    name: string
    access: {
      role: Role
      menus: Array<Menu>
      regions: Array<Region>
    }
  }
}

interface Role {
  name: string
  parentRole: number
}

interface Menu {
  key: string
  name: string
}
interface Region {
  code: string
  name: string
}

export default function useUser() {
  const userStore = useUserStore()

  async function getProfile(): Promise<boolean> {
    try {
      const accessToken = localStorage.getItem('accessToken')

      const response = await fetch('/api/v1/me', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      const data: GetProfileResponse = await response.json()
      userStore.storeUserAccess(
        data.data.email,
        data.data.name,
        data.data.access.role,
        data.data.access.menus,
        data.data.access.regions,
      )
      return true
    } catch (e: any) {
      return false
    }
  }

  return {
    getProfile,
  }
}
