import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

// import RouteViewComponent from '../layouts/RouterBypass.vue'
import useUser from '../api/profile'
import { useUserStore } from '../stores/user-store'
import { useAuthStore } from '../stores/auth.store'

const routes: Array<RouteRecordRaw> = [
  {
    // path: '/:pathMatch(.*)*',
    name: 'Dinus Login',
    path: '/',
    component: AuthLayout,
    redirect: { name: 'login' },
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'logout',
        path: 'logout',
        component: {
          beforeRouteEnter() {
            useAuthStore().cleanAuthStore()
            useAuthStore().$reset()
            useUserStore().$reset()
            return { name: 'login' }
          },
        },
      },
    ],
  },
  {
    name: 'Dinus Dashboard',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    meta: {
      authRequired: 'true',
    },
    children: [
      {
        name: 'general',
        path: 'general',
        component: () => import('../pages/admin/dashboard/General.vue'),
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'regional-stock-report',
        path: '/regional-stock-report',
        // component: RouteViewComponent,
        redirect: { name: 'regional-stock-report-summary' },
        meta: {
          authRequired: 'true',
        },
        children: [
          {
            name: 'regional-stock-report-summary',
            path: 'summary',
            component: () => import('../pages/regional-stock-report/RegionalStockReportSummaryPage.vue'),
          },
          {
            name: 'regional-stock-report-create',
            path: 'create',
            component: () => import('../pages/regional-stock-report/RegionalStockReportCreatePage.vue'),
          },
          {
            name: 'regional-stock-report-history',
            path: 'history',
            component: () => import('../pages/regional-stock-report/RegionalStockReportHistoriesPage.vue'),
          },
        ],
      },
      {
        name: 'stock-report',
        path: 'stock-report',
        meta: {
          authRequired: 'true',
        },
        component: () => import('../pages/stock-report/StockReportPage.vue'),
      },
      {
        name: 'sales-order',
        path: 'sales-order',
        meta: {
          authRequired: 'true',
        },
        component: () => import('../pages/sales-order/SalesOrderDetailedPage.vue'),
      },
      {
        name: 'sales-return',
        path: 'sales-return',
        meta: {
          authRequired: 'true',
        },
        component: () => import('../pages/sales-return/SalesReturnPage.vue'),
      },

      // {
      //   name: 'settings',
      //   path: 'settings',
      //   component: () => import('../pages/settings/Settings.vue'),
      // },
      // {
      //   name: 'preferences',
      //   path: 'preferences',
      //   component: () => import('../pages/preferences/Preferences.vue'),
      // },
      // {
      //   name: 'users',
      //   path: 'users',
      //   component: () => import('../pages/users/UsersPage.vue'),
      // },
      // {
      //   name: 'projects',
      //   path: 'projects',
      //   component: () => import('../pages/projects/ProjectsPage.vue'),
      // },
      // {
      //   name: 'payments',
      //   path: '/payments',
      //   component: RouteViewComponent,
      //   children: [
      //     {
      //       name: 'payment-methods',
      //       path: 'payment-methods',
      //       component: () => import('../pages/payments/PaymentsPage.vue'),
      //     },
      //     {
      //       name: 'billing',
      //       path: 'billing',
      //       component: () => import('../pages/billing/BillingPage.vue'),
      //     },
      //     {
      //       name: 'pricing-plans',
      //       path: 'pricing-plans',
      //       component: () => import('../pages/pricing-plans/PricingPlans.vue'),
      //     },
      //   ],
      // },
      // {
      //   name: 'faq',
      //   path: '/faq',
      //   component: () => import('../pages/faq/FaqPage.vue'),
      // },
    ],
  },

  // {
  //   path: '/auth',
  //   component: AuthLayout,
  //   children: [
  //     {
  //       name: 'login',
  //       path: 'login',
  //       component: () => import('../pages/auth/Login.vue'),
  //     },
  //     {
  //       name: 'signup',
  //       path: 'signup',
  //       component: () => import('../pages/auth/Signup.vue'),
  //     },
  //     {
  //       name: 'recover-password',
  //       path: 'recover-password',
  //       component: () => import('../pages/auth/RecoverPassword.vue'),
  //     },
  //     {
  //       name: 'recover-password-email',
  //       path: 'recover-password-email',
  //       component: () => import('../pages/auth/CheckTheEmail.vue'),
  //     },
  //     {
  //       path: '',
  //       redirect: { name: 'login' },
  //     },
  //   ],
  // },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach(async (to) => {
  if (to.meta.authRequired === 'true') {
    const canAccess = await canUserAccess(to)
    if (!canAccess) return { name: 'login' }
  }
})

const canUserAccess = async (to: RouteLocationNormalized) => {
  // const parent = to.matched[to.matched.length - 2]
  const { getProfile } = useUser()
  const userStore = useUserStore()
  await getProfile()
  const menuKey = to.name != null ? to.name.toString() : ''
  return userStore.isUserAllowedForAccess(menuKey)
}

export default router
