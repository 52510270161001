import { defineStore } from 'pinia'

interface Menu {
  key: string
  name: string
}

interface Role {
  name: string
  parentRole: number
}

export type Region = {
  code: string
  name: string
}

function stringExistsInArray(array: Array<Menu>, key: keyof Menu, value: string): boolean {
  return array.some((obj) => {
    return value.includes(obj[key])
  })
}

export const useUserStore = defineStore('user', {
  state: () => ({
    email: '',
    name: '',
    access: {
      role: {} as Role,
      menus: [] as Menu[],
      regions: [] as Region[],
    },
  }),

  actions: {
    storeUserAccess(email: string, name: string, role: Role, menus: Menu[], regions: Region[]) {
      this.email = email
      this.name = name
      this.access.role = role
      this.access.menus = menus
      this.access.regions = regions
    },
    isUserAllowedForAccess(access: string) {
      return stringExistsInArray(this.access.menus, 'key', access)
    },
    getUserAccess() {
      return this.access.menus.map((obj) => obj.key)
    },
    getUserNameAndAccess() {
      return { name: this.name, role: this.access.role }
    },
    getUserRegionCode() {
      return { regionCode: this.access.regions[0].code }
    },
    getAllUserRegionCodes() {
      return { regionCodes: this.access.regions }
    },
  },
})
